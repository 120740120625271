<template>
  <el-dialog title="提示" :close-on-click-modal="false" :visible.sync="dialogVisible" width="30%" @close="handleClose">
    <el-form ref="form" :model="form" label-width="100px">
      <el-form-item label="积分规则名称">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="积分比例">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="积分系数">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      form: {
        name: '',
      },
      dialogVisible: true,
    }
  },
  methods: {
    handleClose() {
      this.$emit('closeintegral')
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
