<template>
  <div class="organ-management-page">
    <el-row>
      <el-col :span="24">
        <el-card shadow="always">
          <el-form :inline="true" :model="form" class="demo-form-inline searchFrom" size="small">
            <el-form-item>
              <el-input v-model="form.name" :clearable="true"></el-input>
            </el-form-item>
            <el-form-item>
              <el-select v-model="form.region">
                <el-option label="个人用户" value="shanghai"></el-option>
                <el-option label="家庭用户" value="shanghai1"></el-option>
                <el-option label="精英用户" value="beijing"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary">搜索</el-button>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="addIntegral">添加</el-button>
            </el-form-item>
          </el-form>
        </el-card>
      </el-col>
    </el-row>
    <div class="table-block">
      <el-table :data="tableData" :header-cell-style="{'text-align': 'center'}" :cell-style="{'text-align':'center'}" @row-click="rowClick" style="width: 100%">
        <el-table-column type="selection" width="50"></el-table-column>
        <el-table-column prop="date" label="日期"> </el-table-column>
        <el-table-column prop="name" label="姓名"> </el-table-column>
        <el-table-column prop="province" label="省份"> </el-table-column>
        <el-table-column prop="city" label="市区"> </el-table-column>
        <el-table-column prop="address" label="地址"> </el-table-column>
        <el-table-column prop="zip" label="邮编"> </el-table-column>
        <el-table-column label="操作" width="250">
          <template slot-scope="scope">
            <el-button @click="addIntegral(scope.row)" type="primary" size="small">编辑</el-button>
            <el-button type="danger" size="small">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage4"
      :page-sizes="[100, 200, 300, 400]"
      :page-size="100"
      layout="total, sizes, prev, pager, next, jumper"
      :total="400"
      background>
    </el-pagination>
    <integral v-if="showIntegral" @closeintegral="closeintegral"></integral>
  </div>
</template>

<script>
import integral from '@/views/customerManagement/component/integral'
export default {
  data() {
    return {
      form: {
        name: '',
        region: '',
      },
      options: [
        {
          label: 1,
          value: 1.1,
        },
        {
          label: 2,
          value: 2.2,
        },
      ],
      tableData: [
        {
          date: '2016-05-02',
          name: '王小虎',
          province: '上海',
          city: '普陀区',
          address: '上海市普陀区金沙江路 1518 弄',
          zip: 200333,
        },
        {
          date: '2016-05-04',
          name: '王小虎',
          province: '上海',
          city: '普陀区',
          address: '上海市普陀区金沙江路 1517 弄',
          zip: 200333,
        },
        {
          date: '2016-05-01',
          name: '王小虎',
          province: '上海',
          city: '普陀区',
          address: '上海市普陀区金沙江路 1519 弄',
          zip: 200333,
        },
        {
          date: '2016-05-03',
          name: '王小虎',
          province: '上海',
          city: '普陀区',
          address: '上海市普陀区金沙江路 1516 弄',
          zip: 200333,
        },
      ],
      showIntegral: false,
      // currentPage1: 5,
      // currentPage2: 5,
      // currentPage3: 5,
      currentPage4: 1
    }
  },
  components: { integral },
  methods: {
    closeintegral() {
      this.showIntegral = false
    },
    addIntegral() {
      this.showIntegral = true
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
    rowClick(row, column, event){
      console.log(row, 'row', column, 'colunm', event, 'event')
    },
  },
}
</script>

<style lang="scss" scoped>
.organ-management-page {
  background: transparent;
}
.el-table__body tr.current-row>td{
  background-color: #fdf3ea;
  color: #f19944;
}
</style>
